/* global google */
import React, { useMemo, useRef, useState } from "react";
import {
  GoogleMap,
  InfoBox,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import "./App.css";
import useAudioRecorder from "./useAudioRecorder";
import {
  EMPLOYMENT_TYPE_HASH,
  PAY_PERIOD_TEXT,
  addressFormatter,
} from "./constants";
const containerStyle = {
  width: "1240px",
  height: "90%",
  minHeight: "90vh",
  borderRadius: "12px",
};

const center = {
  lat: 40.7582318,
  lng: -74.0030135,
};

interface Job {
  _id: string;
  company: {
    _id: string;
    name: string | number;
    industry?: string;
  };
  industry?: string;
  jobTitle: string;
  jobType?: string[];
  payPeriod?: string;
  isSalaryNegotiable?: boolean;
  currencyType?: string;
  salary?: {
    min?: number;
    max?: number;
  };
  location: {
    line1?: string;
    line2?: string;
    city?: string;
    state?: string;
    country?: string;
    zip?: string;
    lat: number | null;
    lng: number | null;
  };
  description?: string;
  createdAt: string;
}
interface Response {
  job_title: string;
  language: string;
  location: string;
  sentiment: { label: string; score: number }[];
  transcription: string;
  jobs: Job[];
}
function App() {
  const {
    audioFile,
    audioUrl,
    error,
    isRecording,
    permissionGranted,
    recordingTime,
    reset,
    startRecording,
    stopRecording,
  } = useAudioRecorder();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY as string,
  });

  const mapRef = useRef<google.maps.Map | null>(null);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    map.setCenter(center);
    map.setZoom(13);
    mapRef.current = map;
  }, []);

  const [foundJobs, setFoundJobs] = useState<Job[]>([]);

  const uploadAudio = async () => {
    if (!audioFile) {
      alert("File input not found.");
      return;
    }

    if (!audioFile) {
      alert("Please select a audioFile.");
      return;
    }

    const formData = new FormData();
    formData.append("file", audioFile);
    try {
      const response = await fetch("https://ai-api.workwip.com/process_audio", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Server error: ${response.statusText}`);
      }

      const result: Response = await response.json();
      if (!result.jobs || !result.jobs.length) {
        alert("No jobs found!");
        return;
      }
      setFoundJobs(result.jobs);
      const bounds = new google.maps.LatLngBounds();
      result.jobs.forEach((job: Job) => {
        bounds.extend(
          new google.maps.LatLng(job.location.lat ?? 0, job.location.lng ?? 0)
        );
      });
      mapRef.current?.fitBounds(bounds);
    } catch (error) {
      console.log(error);
      alert(`Error uploading file:, details: ${error}`);
    }
  };
  const handleJobListClear = () => {
    setFoundJobs([]);
  };

  const [isRecordExpanded, setIsRecordExpanded] = useState(false);
  const payPeriod = useMemo(() => {
    if (!selectedJob?.payPeriod) return "";
    return ` / ${PAY_PERIOD_TEXT[selectedJob.payPeriod]}`;
  }, [selectedJob?.payPeriod]);

  const moneyFormatter = useMemo(() => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      style: "currency",
      currency: selectedJob?.currencyType || "USD",
    });
  }, [selectedJob]);

  const salary = useMemo(() => {
    if (!selectedJob?.salary) return "Open to discuss later";
    if (selectedJob.salary.min === 0 && selectedJob.salary.max === 0)
      return "Open to discuss later";
    if (
      typeof selectedJob.salary.min === "number" &&
      typeof selectedJob.salary.max === "number"
    ) {
      if (selectedJob.salary.min === selectedJob.salary.max) {
        return `${moneyFormatter.format(selectedJob.salary.min)}${payPeriod}`;
      }
      return `${moneyFormatter.format(
        selectedJob.salary.min
      )}-${moneyFormatter.format(selectedJob.salary.max)}${payPeriod}`;
    }
    if (
      typeof selectedJob.salary.min === "number" &&
      !!selectedJob.salary.min &&
      !selectedJob.salary.max
    ) {
      return `${moneyFormatter.format(selectedJob.salary.min)} ~ ${payPeriod}`;
    }
    if (
      typeof selectedJob.salary.max === "number" &&
      !!selectedJob.salary.max &&
      !selectedJob.salary.min
    ) {
      return `~ ${moneyFormatter.format(selectedJob.salary.max)}${payPeriod}`;
    }
    return "Open to discuss later";
  }, [selectedJob?.salary, payPeriod, moneyFormatter]);

  return (
    <div
      className="background"
      style={{
        width: "100%",
        minHeight: "100vh",
        minWidth: "1440px",
        paddingTop: "2rem",
        background: "linear-gradient(45deg, #d46bfe75 30%, #ff8f5375 90%)",
      }}
    >
      {/* FAB */}
      {isRecordExpanded ? (
        <div
          style={{
            position: "fixed",
            bottom: 10,
            right: 120,
            zIndex: 9999,
            transform: "translateX(50%)",
            backgroundColor: "#fff",
            padding: "1rem 1rem 0.25rem",
            boxShadow: "0 2 10px rgba(0, 0, 0, 0.2)",
            borderRadius: "12px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "0.5rem",
            }}
          >
            <button
              style={{
                padding: "0.5rem 1.15rem",
                borderRadius: "8px",
                background: "#fff",
                border: "1px solid #2d63e2",
              }}
              onClick={startRecording}
              disabled={isRecording}
            >
              Start Recording
            </button>
            <button
              style={{
                padding: "0.5rem 1.15rem",
                borderRadius: "8px",
                background: "#fff",
                border: "1px solid #2d63e2",
              }}
              onClick={stopRecording}
              disabled={!isRecording}
            >
              Stop Recording
            </button>
            <button
              style={{
                padding: "0.5rem 1.15rem",
                borderRadius: "8px",
                background: "#fff",
                border: "1px solid #2d63e2",
              }}
              onClick={reset}
              disabled={isRecording}
            >
              Reset
            </button>
          </div>

          {permissionGranted ? (
            <div>
              {audioFile && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <audio
                    controls
                    src={audioUrl ?? ""}
                    style={{ width: "200px", height: "80px" }}
                  />
                  <button
                    style={{
                      padding: "2rem",
                      borderRadius: "9999px",
                      width: "56px",
                      height: "56px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={uploadAudio}
                  >
                    Upload
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div>{error || "Permission not granted"}</div>
          )}
          <p>
            User Status:{" "}
            {isRecording
              ? `Recording time: ${recordingTime} seconds`
              : "Not recording"}{" "}
          </p>
          <button
            style={{
              width: "100%",

              padding: "0.25rem 1rem",
              boxShadow: "0 2 10px rgba(0, 0, 0, 0.2)",
              border: "1px solid red",
              alignSelf: "center",
              borderRadius: "8px",
            }}
            className={`${
              foundJobs.length > 0 ? "found-job" : "not-found-job"
            }`}
            onClick={() => setIsRecordExpanded(false)}
          >
            Flip Record
          </button>
        </div>
      ) : (
        <button
          style={{
            position: "fixed",
            bottom: 10,
            right: 80,
            zIndex: 9999,
            transform: "translateX(50%)",
            backgroundColor: "rgba(255, 255, 255, 0.78)",
            padding: "0.25rem 1rem",
            boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.2)",
            border: 0,
            width: "100px",
            height: "100px",
            borderRadius: "9999px",
          }}
          onClick={() => setIsRecordExpanded(true)}
        >
          Expand Record
        </button>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "2rem",
          padding: "0rem 2rem",
          borderRadius: "12px",
          overflow: "hidden",
        }}
      >
        {isLoaded && (
          <GoogleMap
            onLoad={onLoad}
            clickableIcons={false}
            mapContainerStyle={containerStyle}
            options={{
              minZoom: 4,
              maxZoom: 19,
              gestureHandling: "greedy",
            }}
            center={
              selectedJob
                ? new google.maps.LatLng(
                    selectedJob?.location.lat ?? 0,
                    selectedJob?.location.lng ?? 0
                  )
                : center
            }
          >
            {/* Child components, such as markers, info windows, etc. */}
            {foundJobs.map((job) => {
              return (
                <Marker
                  key={job._id}
                  position={{
                    lat: job.location.lat ?? 0,
                    lng: job.location.lng ?? 0,
                  }}
                  onClick={() => {
                    setSelectedJob(job);
                  }}
                />
              );
            })}
            {selectedJob && (
              <InfoBox
                position={
                  new google.maps.LatLng(
                    selectedJob.location.lat ?? 0,
                    selectedJob.location.lng ?? 0
                  )
                }
                options={{
                  boxClass: "info-box",
                  pixelOffset: new google.maps.Size(46, 0),
                  closeBoxURL: "",
                  enableEventPropagation: true,
                  disableAutoPan: true,
                  visible: !!selectedJob,
                }}
              >
                <div className="info-box-content">
                  {selectedJob.industry && (
                    <div className="info-box-item">
                      <span className="info-box-label">Industry:</span>{" "}
                      {selectedJob.industry}
                    </div>
                  )}
                  <div className="info-box-item">
                    <span className="info-box-label">Job Title:</span>{" "}
                    {selectedJob.jobTitle}
                  </div>
                  <div className="info-box-item">
                    <span className="info-box-label">Company Name:</span>{" "}
                    {selectedJob.company.name}
                  </div>
                  <div className="info-box-item">
                    <span className="info-box-label">Address:</span>{" "}
                    {addressFormatter(selectedJob.location as any)}
                  </div>
                  {selectedJob.jobType && !!selectedJob.jobType.length && (
                    <div className="info-box-item">
                      <span className="info-box-label">Employment Type:</span>{" "}
                      {selectedJob.jobType
                        .map(
                          (type) =>
                            EMPLOYMENT_TYPE_HASH[
                              type as keyof typeof EMPLOYMENT_TYPE_HASH
                            ]
                        )
                        .join(", ")}
                    </div>
                  )}
                  {salary && (
                    <div className="info-box-item">
                      <span className="info-box-label">Salary:</span> {salary}
                    </div>
                  )}
                </div>
              </InfoBox>
            )}
            <></>
          </GoogleMap>
        )}
        {foundJobs.length > 0 && (
          <>
            <div
              style={{
                fontSize: "2rem",
                color: "white",
                textAlign: "center",
                width: "400px",
                height: "90%",
                minHeight: "90vh",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                background: "rgba(0, 0, 0, 0.3)",
                borderRadius: "12px",
                boxSizing: "border-box",
                padding: "1rem",
              }}
            >
              Job List
              <div
                style={{
                  width: "330px",

                  overflowY: "auto",
                  borderRadius: "12px",
                }}
              >
                {foundJobs.map((job) => {
                  return (
                    <button
                      key={job._id}
                      className={`job-item ${
                        selectedJob?._id === job._id ? "job-item-selected" : ""
                      }`}
                      onClick={() => {
                        setSelectedJob(job);
                        if (mapRef.current)
                          mapRef.current.panTo({
                            lat: job.location.lat ?? 0,
                            lng: job.location.lng ?? 0,
                          });
                      }}
                    >
                      <div className="job-title">{job.jobTitle}</div>
                      <div className="job-company">
                        {job.company.name} ({job.industry})
                      </div>
                      <div className="job-location">
                        {job.location.line1} {job.location.city},{" "}
                        {job.location.state} {job.location.zip}
                      </div>
                      {job.salary && <div className="job-salary">{salary}</div>}
                    </button>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
      <button
        onClick={handleJobListClear}
        style={{
          marginTop: "4px",
          marginLeft: "2rem",
          padding: "1rem 2rem",
          borderRadius: "12px",
          boxShadow: "2px 2px 4px pink",
          border: 0,
        }}
      >
        Clear JobList
      </button>
    </div>
  );
}

export default App;
